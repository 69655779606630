import React, { useState, useEffect, PropTypes } from "react"
import Autosuggest from 'react-autosuggest';
import _ from "lodash"
import axios from 'axios';




// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => {
    return(
        <div>
            {suggestion.name}
        </div>
    )
};


class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: [],
            areas: [],
            aliasVal:"",
            isChages:false
        };
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
            aliasVal: newValue,
            isChages: true
        });
        if (this.props?.setAreaVal)
            this.props.setAreaVal(newValue)
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    suggestionSelected = (e, {suggestion})=>{

        if(suggestion){
        this.setState({
            value: '', // suggestion.name,
            aliasVal: suggestion.slug,
        });
        if ( this.props.setAreaVal )
            this.props.setAreaVal(suggestion.slug)
        this.props.handleMultiAreaFun(suggestion);
        }
    }
    getitems = async url => {
        try {
            const { data } = await axios.get(url, {})
            if (this.props?.setAreasList)
                this.props.setAreasList(data)
            this.setState({ areas: data })
        } catch (error) {
        console.error(error)
        }
    }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.state.areas.filter(area =>
            // area.name.toLowerCase().slice(0, inputLength) === inputValue
            area.name.toLowerCase().includes(inputValue.toLowerCase())
        );
    };


    componentDidMount() {
        let url = `${process.env.GATSBY_STRAPI_SRC}/${this.props?.fileName || 'areas.json'}`
        this.getitems(url)
        if (this.props?.areaVal)
            this.setState({value: this.props.areaVal})

    }

    render() {
        const { value, suggestions, isChages, aliasVal } = this.state;

        const autosuggestInputProps = this.props?.autosuggestInputProps || {}

        // Autosuggest will pass through all these props to the input.
        var setVal = value;
        if(_.isEmpty(setVal) && !isChages){
            if (this.props?.areaVal)
                setVal = this.props.areaVal;
        }

        const inputProps = {
            placeholder: this.props?.placeholder || 'Community or Tower',
            value: setVal,
            onChange: this.onChange,
            // ...autosuggestInputProps
        };

        // Finally, render it!
        return (
            <>
                <Autosuggest
                    suggestions={suggestions.slice(0, 10)}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected={this.suggestionSelected}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
                <input type="hidden" className="searchVal" value={aliasVal}/>
            </>
        );
    }
}

export default SearchBox;
