import clsx from 'clsx'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { getWhatsAppURL } from '../../common/misc'
import useHasScrolled from '../../hooks/useHasScrolled'
import EnquireModal from '../EnquireModal/EnquireModal'
import BurgerMenu from './BurgerMenu'
import Logo from "../../images/icons/logo.svg"
import LogoLight from "../../images/icons/logo-light.svg"
import SearchOverlay from "../SearchOverlay/SearchFilter"
import { SaveItem, useAuthState } from "@myaccount/website-service"
import { phoneNumber } from '../common/site/utils'
import { useMedia } from "react-use"

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""

const HeaderHomeMobile = () => {

  //
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()
  //

  const [modalShow, setModalShow] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)

  const scroll = useHasScrolled()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useMedia("(max-width: 767px)", false)

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlay = () => {
    if (showOverlay) setShowOverlay(false)
    else setShowOverlay(true)
  }

  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const trackerEvent = event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: event,
      formType: event + " - " + shareurl,
      formId: event,
      formName: event,
      formLabel: event,
    })
  }

    return (
        <>
          <header
            className={clsx(`header mobile-header transparent-header`, {
              scrolled: scroll,             
            })}
            id="header"
          >
            <>
              <div className="logo-wrap">
                <Link to="/" className="navbar-brand">
                  {!scroll ? (
                    <img src={LogoLight} alt="logo" className="brand-logo" />
                  ) : (
                    <img src={Logo} alt="logo" className="brand-logo" />
                  )}
                </Link>
              </div>
              <div className="header-actions">
                {!isMobile ? 
              <Dropdown
                  className="overlay-dropdown"
                  onToggle={handleOverlay}
                  show={showOverlay}
                  autoClose={false}
                >
                  <Dropdown.Toggle className="overlay-toggle">
                    <i className="icon-search"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="overlay-menu">
                    <SearchOverlay handleOverlay={handleOverlay} overlay />
                    {showOverlay && (
                      <div className="search-overlay" onClick={handleOverlay}></div>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                :
                <button className="d-flex align-items-center md-action" onClick={handleButtonClick}> <i className="icon-search"></i></button>
                }
                <a href={getWhatsAppURL(phoneNumber)} target="_blank">
                  <i className="icon-whatsapp"></i>
                </a>
                <a
                  href={`tel:${phoneNumber}`}
                  className="d-flex align-items-center md-action "
                  onClick={() => trackerEvent("Phone number click")}
                >
                  <i className="icon-phone"></i>
                  <span className="d-none d-lg-block header-cta-text">{phoneNumber}</span>
                </a>
    
                <button
                  className="d-flex align-items-center md-action"
                  onClick={() => setModalShow(true)}
                >
                  <i className="icon-msg"></i>
                  <span className="d-none d-lg-block header-cta-text">Enquire</span>
                </button>
                <a
                  href={myaccountURL}
                  className="d-flex align-items-center md-action sb-myacc"
                >
                  {isAuthenticated ? (
                    <i className="icon-user-active"></i>
                  ) : (
                    <i className="icon-user"></i>
                  )}
                  <span className="d-none d-lg-block header-cta-text">my account</span>
                </a>
                <Link
                  to="/property-valuation-in-dubai/"
                  className="btn btn-white d-none d-sm-flex"
                >
                    Free Valuation
                </Link>
                <BurgerMenu />
              </div>
            </>
          </header>
          {isModalOpen ? <SearchOverlay modalShow={isModalOpen} closeModal={closeModal} /> : '' }
          <EnquireModal show={modalShow} onHide={() => setModalShow(false)} />
        </>
      )
}

export default HeaderHomeMobile