// Property type

export const OffPlanPropertyType = [
    {label: "Type", value: ""},
    {label: "Apartment", value: "apartment"},
    {label: "Villa", value: "villa"},
    {label: "Penthouse", value: "penthouse"},
    {label: "Townhouse", value: "townhouse"},
    {label: "Duplex", value: "duplex"},
    // {label: "Studio", value: "studio"},
]

export const resiSalesPropertyType = [
    {label: "Type", value: ""},
    {label: "Apartment", value: "apartment"},
    {label: "Villa", value: "villa"},
    {label: "Townhouse", value: "townhouse"},
    // {label: "Duplex", value: "duplex"},
    // {label: "Studio", value: "studio"},
    {label: "Penthouse", value: "penthouse"},
    // {label: "Land", value: "land"},
    // {label: "Lot", value: "lot"},
    // {label: "Office Space", value: "office-space"},
    // {label: "Office", value: "office"},
    // {label: "Labor Camp", value: "labor-camp"},
    // {label: "Plot", value: "plot"},
    // {label: "Retail", value: "retail"},
    // {label: "Shop", value: "shop"},
    // {label: "Warehouse", value: "warehouse"},
]

export const resiLettingsPropertyType = [
    {label: "Type", value: ""},
    {label: "Apartment", value: "apartment"},
    {label: "Villa", value: "villa"},
    {label: "Townhouse", value: "townhouse"},
    {label: "Land", value: "land"},
    {label: "Whole Building", value: "whole-building"},
    {label: "Penthouse", value: "penthouse"},
    {label: "Duplex", value: "duplex"},
    {label: "Office Space", value: "office-space"},
    {label: "Office", value: "office"},
    {label: "Labor Camp", value: "labor-camp"},
    {label: "Plot", value: "plot"},
    {label: "Retail", value: "retail"},
    {label: "Shop", value: "shop"},
    {label: "Warehouse", value: "warehouse"},
]
// Property type
export const Furnishing = [
    {label: "Select Furnishing", value: ""},
    {label: "Yes", value: "yes"},
    {label: "No", value: "no"},
    {label:"Partly", value:"partly"},
]

export const VirtualViewing = [
    {label: "Select Viewing", value: ""},
    {label: "360 Tours", value: "360-tour"},
    // {label: "3D Tour", value: "3d-tour"},
    {label:"Video Tours", value:"video-tour"},
]
// Price Min Residential Sales
export const priceminRangeResiSale = [
    {
        value: "0",
        label: "No Min",
    },
    {
        value: "300000",
        label: "AED 300,000",
    },
    {
        value: "400000",
        label: "AED 400,000",
    },
    {
        value: "500000",
        label: "AED 500,000",
    },
    {
        value: "600000",
        label: "AED 600,000",
    },
    {
        value: "700000",
        label: "AED 700,000",
    },
    {
        value: "800000",
        label: "AED 800,000",
    },
    {
        value: "900000",
        label: "AED 900,000",
    },
    {
        value: "1000000",
        label: "AED 1,000,000",
    },
    {
        value: "1100000",
        label: "AED 1,100,000",
    },
    {
        value: "1200000",
        label: "AED 1,200,000",
    },
    {
        value: "1300000",
        label: "AED 1,300,000",
    },
    {
        value: "1400000",
        label: "AED 1,400,000",
    },
    {
        value: "1500000",
        label: "AED 1,500,000",
    },
    {
        value: "1600000",
        label: "AED 1,600,000",
    },
    {
        value: "1700000",
        label: "AED 1,700,000",
    },
    {
        value: "1800000",
        label: "AED 1,800,000",
    },
    {
        value: "1900000",
        label: "AED 1,900,000",
    },
    {
        value: "2000000",
        label: "AED 2,000,000",
    },
    {
        value: "2100000",
        label: "AED 2,100,000",
    },
    {
        value: "2200000",
        label: "AED 2,200,000",
    },
    {
        value: "2300000",
        label: "AED 2,300,000",
    },
    {
        value: "2400000",
        label: "AED 2,400,000",
    },
    {
        value: "2500000",
        label: "AED 2,500,000",
    },
    {
        value: "2600000",
        label: "AED 2,600,000",
    },
    {
        value: "2700000",
        label: "AED 2,700,000",
    },
    {
        value: "2800000",
        label: "AED 2,800,000",
    },
    {
        value: "2900000",
        label: "AED 2,900,000",
    },
    {
        value: "3000000",
        label: "AED 3,000,000",
    },
    {
        value: "3250000",
        label: "AED 3,250,000",
    },
    {
        value: "3500000",
        label: "AED 3,500,000",
    },
    {
        value: "3750000",
        label: "AED 3,750,000",
    },
    {
        value: "4000000",
        label: "AED 4,000,000",
    },
    {
        value: "4250000",
        label: "AED 4,250,000",
    },
    {
        value: "4500000",
        label: "AED 4,500,000",
    },
    {
        value: "5000000",
        label: "AED 5,000,000",
    },
    {
        value: "6000000",
        label: "AED 6,000,000",
    },
    {
        value: "7000000",
        label: "AED 7,000,000",
    },
    {
        value: "8000000",
        label: "AED 8,000,000",
    },
    {
        value: "9000000",
        label: "AED 9,000,000",
    },
    {
        value: "10000000",
        label: "AED 10,000,000",
    },
    {
        value: "25000000",
        label: "AED 25,000,000",
    },
    {
        value: "50000000",
        label: "AED 50,000,000",
    }

]
// Price Min Residential Sales
  
// Price Max Residential Sales
export const pricemaxRangeResiSale = [
    {
        value: "0",
        label: "No Max",
    },
    {
        value: "300000",
        label: "AED 300,000",
    },
    {
        value: "400000",
        label: "AED 400,000",
    },
    {
        value: "500000",
        label: "AED 500,000",
    },
    {
        value: "600000",
        label: "AED 600,000",
    },
    {
        value: "700000",
        label: "AED 700,000",
    },
    {
        value: "800000",
        label: "AED 800,000",
    },
    {
        value: "900000",
        label: "AED 900,000",
    },
    {
        value: "1000000",
        label: "AED 1,000,000",
    },
    {
        value: "1100000",
        label: "AED 1,100,000",
    },
    {
        value: "1200000",
        label: "AED 1,200,000",
    },
    {
        value: "1300000",
        label: "AED 1,300,000",
    },
    {
        value: "1400000",
        label: "AED 1,400,000",
    },
    {
        value: "1500000",
        label: "AED 1,500,000",
    },
    {
        value: "1600000",
        label: "AED 1,600,000",
    },
    {
        value: "1700000",
        label: "AED 1,700,000",
    },
    {
        value: "1800000",
        label: "AED 1,800,000",
    },
    {
        value: "1900000",
        label: "AED 1,900,000",
    },
    {
        value: "2000000",
        label: "AED 2,000,000",
    },
    {
        value: "2100000",
        label: "AED 2,100,000",
    },
    {
        value: "2200000",
        label: "AED 2,200,000",
    },
    {
        value: "2300000",
        label: "AED 2,300,000",
    },
    {
        value: "2400000",
        label: "AED 2,400,000",
    },
    {
        value: "2500000",
        label: "AED 2,500,000",
    },
    {
        value: "2600000",
        label: "AED 2,600,000",
    },
    {
        value: "2700000",
        label: "AED 2,700,000",
    },
    {
        value: "2800000",
        label: "AED 2,800,000",
    },
    {
        value: "2900000",
        label: "AED 2,900,000",
    },
    {
        value: "3000000",
        label: "AED 3,000,000",
    },
    {
        value: "3250000",
        label: "AED 3,250,000",
    },
    {
        value: "3500000",
        label: "AED 3,500,000",
    },
    {
        value: "3750000",
        label: "AED 3,750,000",
    },
    {
        value: "4000000",
        label: "AED 4,000,000",
    },
    {
        value: "4250000",
        label: "AED 4,250,000",
    },
    {
        value: "4500000",
        label: "AED 4,500,000",
    },
    {
        value: "5000000",
        label: "AED 5,000,000",
    },
    {
        value: "6000000",
        label: "AED 6,000,000",
    },
    {
        value: "7000000",
        label: "AED 7,000,000",
    },
    {
        value: "8000000",
        label: "AED 8,000,000",
    },
    {
        value: "9000000",
        label: "AED 9,000,000",
    },
    {
        value: "10000000",
        label: "AED 10,000,000",
    },
    {
        value: "25000000",
        label: "AED 25,000,000",
    },
    {
        value: "50000000",
        label: "AED 50,000,000",
    }


]
// Price Max Residential Sales
  
// Price Min Residential Lettings
export const priceminRangeResiRent = [
    {
        value: "0",
        label: "No Min",
    },
    {
        value: "5000",
        label: "AED 5,000",
    },
    {
        value: "10000",
        label: "AED 10,000",
    },
    {
        value: "20000",
        label: "AED 20,000",
    },
    {
        value: "30000",
        label: "AED 30,000",
    },
    {
        value: "40000",
        label: "AED 40,000",
    },
    {
        value: "50000",
        label: "AED 50,000",
    },
    {
        value: "60000",
        label: "AED 60,000",
    },
    {
        value: "70000",
        label: "AED 70,000",
    },
    {
        value: "80000",
        label: "AED 80,000",
    },
    {
        value: "90000",
        label: "AED 90,000",
    },
    {
        value: "100000",
        label: "AED 100,000",
    },
    {
        value: "110000",
        label: "AED 110,000",
    },
    {
        value: "120000",
        label: "AED 120,000",
    },
    {
        value: "130000",
        label: "AED 130,000",
    },
    {
        value: "140000",
        label: "AED 140,000",
    },
    {
        value: "150000",
        label: "AED 150,000",
    },
    {
        value: "160000",
        label: "AED 160,000",
    },
    {
        value: "170000",
        label: "AED 170,000",
    },
    {
        value: "180000",
        label: "AED 180,000",
    },
    {
        value: "190000",
        label: "AED 190,000",
    },
    {
        value: "200000",
        label: "AED 200,000",
    },
    {
        value: "225000",
        label: "AED 225,000",
    },
    {
        value: "250000",
        label: "AED 250,000",
    },
    {
        value: "275000",
        label: "AED 275,000",
    },
    {
        value: "300000",
        label: "AED 300,000",
    },
    {
        value: "350000",
        label: "AED 350,000",
    },
    {
        value: "400000",
        label: "AED 400,000",
    },
    {
        value: "500000",
        label: "AED 500,000",
    },
    {
        value: "750000",
        label: "AED 750,000",
    },
    {
        value: "1000000",
        label: "AED 1,000,000",
    }


]
// Price Min Residential Lettings
  
// Price Max Residential Lettings
export const pricemaxRangeResiRent = [
    {
        value: "0",
        label: "No Max",
    },
    {
        value: "5000",
        label: "AED 5,000",
    },
    {
        value: "10000",
        label: "AED 10,000",
    },
    {
        value: "20000",
        label: "AED 20,000",
    },
    {
        value: "30000",
        label: "AED 30,000",
    },
    {
        value: "40000",
        label: "AED 40,000",
    },
    {
        value: "50000",
        label: "AED 50,000",
    },
    {
        value: "60000",
        label: "AED 60,000",
    },
    {
        value: "70000",
        label: "AED 70,000",
    },
    {
        value: "80000",
        label: "AED 80,000",
    },
    {
        value: "90000",
        label: "AED 90,000",
    },
    {
        value: "100000",
        label: "AED 100,000",
    },
    {
        value: "110000",
        label: "AED 110,000",
    },
    {
        value: "120000",
        label: "AED 120,000",
    },
    {
        value: "130000",
        label: "AED 130,000",
    },
    {
        value: "140000",
        label: "AED 140,000",
    },
    {
        value: "150000",
        label: "AED 150,000",
    },
    {
        value: "160000",
        label: "AED 160,000",
    },
    {
        value: "170000",
        label: "AED 170,000",
    },
    {
        value: "180000",
        label: "AED 180,000",
    },
    {
        value: "190000",
        label: "AED 190,000",
    },
    {
        value: "200000",
        label: "AED 200,000",
    },
    {
        value: "225000",
        label: "AED 225,000",
    },
    {
        value: "250000",
        label: "AED 250,000",
    },
    {
        value: "275000",
        label: "AED 275,000",
    },
    {
        value: "300000",
        label: "AED 300,000",
    },
    {
        value: "350000",
        label: "AED 350,000",
    },
    {
        value: "400000",
        label: "AED 400,000",
    },
    {
        value: "500000",
        label: "AED 500,000",
    },
    {
        value: "750000",
        label: "AED 750,000",
    },
    {
        value: "1000000",
        label: "AED 1,000,000",
    }

]
// Price Max Residential Lettings
  
// Bedrooms
export const bedroomsRange = [
    {label: "Beds", value: ""},
    {label: "1", value: "1"},
    {label: "2", value: "2"},
    {label: "3", value: "3"},
    {label: "4", value: "4"},
    {label: "5", value: "5"},
    {label: "6", value: "6"},
    // {label: "7", value: "7"},
    // {label: "8", value: "8"},
    // {label: "9", value: "9"},
]
// Bedrooms

// Bedrooms
export const bedroomsMinRange = [
    {label: "Min Beds", value: ""},
    {label: "1", value: "1"},
    {label: "2", value: "2"},
    {label: "3", value: "3"},
    {label: "4", value: "4"},
    {label: "5", value: "5"},
    {label: "6", value: "6"},
    {label: "7", value: "7"},
    {label: "8", value: "8"},
    {label: "9", value: "9"},
]
// Bedrooms

// Bedrooms
export const bedroomsMaxRange = [
    {label: "Max Beds", value: ""},
    {label: "1", value: "1"},
    {label: "2", value: "2"},
    {label: "3", value: "3"},
    {label: "4", value: "4"},
    {label: "5", value: "5"},
    {label: "6", value: "6"},
    {label: "7", value: "7"},
    {label: "8", value: "8"},
    {label: "9", value: "9"},
]
// Bedrooms



// SQFT MIN
export const sqftMinRange = [
    {label: "Min Area", value: ""},
    {label: "500 Sqft", value: "500"},
    {label: "600 Sqft", value: "600"},
    {label: "700 Sqft", value: "700"},
    {label: "800 Sqft", value: "800"},
    {label: "900 Sqft", value: "900"},
    {label: "1,000 Sqft", value: "1000"},
    {label: "1,100 Sqft", value: "1100"},
    {label: "1,200 Sqft", value: "1200"},
    {label: "1,300 Sqft", value: "1300"},
    {label: "1,400 Sqft", value: "1400"},
    {label: "1,500 Sqft", value: "1500"},
    {label: "1,600 Sqft", value: "1600"},
    {label: "1,700 Sqft", value: "1700"},
    {label: "1,800 Sqft", value: "1800"},
    {label: "1,900 Sqft", value: "1900"},
    {label: "2,000 Sqft", value: "2000"},
    {label: "2,200 Sqft", value: "2200"},
    {label: "2,400 Sqft", value: "2400"},
    {label: "2,600 Sqft", value: "2600"},
    {label: "2,800 Sqft", value: "2800"},
    {label: "3,000 Sqft", value: "3000"},
    {label: "3,200 Sqft", value: "3200"},
    {label: "3,400 Sqft", value: "3400"},
    {label: "3,600 Sqft", value: "3600"},
    {label: "3,800 Sqft", value: "3800"},
    {label: "4,200 Sqft", value: "4200"},
    {label: "4,600 Sqft", value: "4600"},
    {label: "5,000 Sqft", value: "5000"},
    {label: "5,400 Sqft", value: "5400"},
    {label: "5,800 Sqft", value: "5800"},
    {label: "6,200 Sqft", value: "6200"},
    {label: "6,600 Sqft", value: "6600"},
    {label: "7,000 Sqft", value: "7000"},
    {label: "7,400 Sqft", value: "7400"},
    {label: "7,800 Sqft", value: "7800"},
    {label: "8,200 Sqft", value: "8200"},
    {label: "9,000 Sqft", value: "9000"},
]
// SQFT MIN


// SQFT MAX
export const sqftMaxRange = [
    {label: "Max Area", value: ""},
    {label: "500 Sqft", value: "500"},
    {label: "600 Sqft", value: "600"},
    {label: "700 Sqft", value: "700"},
    {label: "800 Sqft", value: "800"},
    {label: "900 Sqft", value: "900"},
    {label: "1,000 Sqft", value: "1000"},
    {label: "1,100 Sqft", value: "1100"},
    {label: "1,200 Sqft", value: "1200"},
    {label: "1,300 Sqft", value: "1300"},
    {label: "1,400 Sqft", value: "1400"},
    {label: "1,500 Sqft", value: "1500"},
    {label: "1,600 Sqft", value: "1600"},
    {label: "1,700 Sqft", value: "1700"},
    {label: "1,800 Sqft", value: "1800"},
    {label: "1,900 Sqft", value: "1900"},
    {label: "2,000 Sqft", value: "2000"},
    {label: "2,200 Sqft", value: "2200"},
    {label: "2,400 Sqft", value: "2400"},
    {label: "2,600 Sqft", value: "2600"},
    {label: "2,800 Sqft", value: "2800"},
    {label: "3,000 Sqft", value: "3000"},
    {label: "3,200 Sqft", value: "3200"},
    {label: "3,400 Sqft", value: "3400"},
    {label: "3,600 Sqft", value: "3600"},
    {label: "3,800 Sqft", value: "3800"},
    {label: "4,200 Sqft", value: "4200"},
    {label: "4,600 Sqft", value: "4600"},
    {label: "5,000 Sqft", value: "5000"},
    {label: "5,400 Sqft", value: "5400"},
    {label: "5,800 Sqft", value: "5800"},
    {label: "6,200 Sqft", value: "6200"},
    {label: "6,600 Sqft", value: "6600"},
    {label: "7,000 Sqft", value: "7000"},
    {label: "7,400 Sqft", value: "7400"},
    {label: "7,800 Sqft", value: "7800"},
    {label: "8,200 Sqft", value: "8200"},
    {label: "9,000 Sqft", value: "9000"},
]
// SQFT MAX



// Bathrooms
export const bathroomsRange = [
    {label: "Bathrooms", value: ""},
    {label: "1+", value: "1"},
    {label: "2+", value: "2"},
    {label: "3+", value: "3"},
    {label: "4+", value: "4"},
    {label: "5+", value: "5"},
    {label: "6+", value: "6"},
    {label: "7+", value: "7"},
    {label: "8+", value: "8"},
    {label: "9+", value: "9"},
]
// Bathrooms
// Bedrooms
export const ParkingRange = [
    {label: "Select Parking", value: ""},
    {label: "1+", value: "1"},
    {label: "2+", value: "2"},
    {label: "3+", value: "3"},
    {label: "4+", value: "4"},
    {label: "5+", value: "5"},
]
// Bedrooms

// SQFT
export const sqftRange = [
    {label: "Select Size", value: ""},
    {label: "0 - 1,000 Sqft", value: "0-1000"},
    {label: "1,000 - 2,000 Sqft", value: "1000-2000"},
    {label: "2,000 - 3,000 Sqft", value: "2000-3000"},
    {label: "3,000 - 4,000 Sqft", value: "3000-4000"},
    {label: "4,000 - 5,000 Sqft", value: "4000-5000"},
    {label: "5,000 - 6,000 Sqft", value: "5000-6000"},
    {label: "6,000 - 7,000 Sqft", value: "6000-7000"},
    {label: "7,000 - 8,000 Sqft", value: "7000-8000"},
    {label: "8,000 - 9,000 Sqft", value: "8000-9000"},
    {label: "9,000 - 10,000 Sqft", value: "9000-10000"},
    {label: "10,000 - 11,000 Sqft", value: "10000-11000"},
    {label: "11,000 - 12,000 Sqft", value: "11000-12000"},
    {label: "12,000 - 13,000 Sqft", value: "12000-13000"},
    {label: "13,000 - 14,000 Sqft", value: "13000-14000"},
    {label: "14,000 - 15,000 Sqft", value: "14000-15000"},
]
// SQFT

// Ptypes
export const propertyTypes = [
    { value: 'property/for-sale/in-dubai', label: 'Buy' },
    { value: 'property/for-rent/in-dubai', label: 'Rent' },
]
export const BannerpropertyTypes = [
    { value: 'sale', label: 'Buy' },
    { value: 'rent', label: 'Rent' },
]
export const BannerpropertyTypesRent = [
    { value: 'rent', label: 'Buy' },
    { value: 'sale', label: 'Sale' },
]

export const propertyTypesMap = [
    { value: 'property-map/for-sale/in-dubai', label: 'Buy' },
    { value: 'property-map/for-rent/in-dubai', label: 'Rent' },
]

export const propertyTypesBanner = [
    { value: 'Buy', label: 'Buy' },
    { value: 'Rent', label: 'Rent' },
]

// Property amenities checkboxes
export const amenitiesCheckboxArray = [
    {label:"Balcony",value:"balcony", checked: false},
    {label:"Barbecue Area",value:"barbecue-area", checked: false},
    {label:"Brand new",value:"brand-new", checked: false},
    {label:"Built in Kitchen Appliances",value:"built-in-kitchen-appliances", checked: false},
    {label:"Built in Wardrobes",value:"built-in-wardrobes", checked: false},
    {label:"Central A/C & Heating",value:"central-ac-heating", checked: false},
    {label:"Children's Play Area",value:"childrens-play-area", checked: false},
    {label:"Children's Pool",value:"childrens-pool", checked: false},
    {label:"Close to Park",value:"close-to-park", checked: false},
    {label:"Concierge Service",value:"concierge-service", checked: false},
    {label:"Covered Parking",value:"covered-parking", checked: false},
    {label:"Maid Service",value:"maid-service", checked: false},
    {label:"Maids Room",value:"maids-room", checked: false},
    {label:"Pets Allowed",value:"pets-allowed", checked: false},
    {label:"Private Garden",value:"private-garden", checked: false},
    {label:"Private Gym",value:"private-gym", checked: false},
    {label:"Private Pool",value:"private-pool", checked: false},
    {label:"Security",value:"security", checked: false},
    {label:"Shared Garden",value:"shared-garden", checked: false},
    {label:"Shared Gym",value:"shared-gym", checked: false},
    {label:"Shared Pool",value:"shared-pool", checked: false},
    {label:"Shared Spa",value:"shared-spa", checked: false},
    {label:"Study",value:"study", checked: false},
    {label:"View of Landmark",value:"view-of-landmark", checked: false},
    {label:"View of Water",value:"view-of-water", checked: false},
    {label:"Walk in Closet",value:"walk-in-closet", checked: false},
    {label:"Water Views",value:"water-views", checked: false}
  ]
  
  export const data = [
    {
      name: "test1",
      result: "pass"
    },
    {
      name: "test2",
      result: "pass"
    },
    {
      name: "test3",
      result: "pass"
    },
    {
      name: "test4",
      result: "pass"
    },
    {
      name: "test5",
      result: "pass"
    },
    {
      name: "test6",
      result: "pass"
    }
  ];

  export const Developer = [
    {label: "Developer", value: ""},
    {label: "Damac", value: "Damac"},
    {label: "Emaar", value: "Emaar"},
    // {label: "Nakheel", value: "Nakheel"},
    // {label: "Dubai Properties", value: "Dubai"},
    {label: "Meraas", value: "Meraas"},
    // {label: "Meydan", value: "Meydan"},
    {label: "Sobha Realty", value: "Sobha Realty"},
    // {label: "Deyaar", value: "Deyaar"},
    // {label: "Omniyat", value: "Omniyat"},
    // {label: "Mag Property Development", value: "Mag Property Development"},
    {label: "Majid Al Futtaim", value: "Majid Al Futtaim"},
    // {label: "Ellington Properties", value: "Ellington"},
    {label: "Binghatti", value: "Binghatti"},
    // {label: "Bloom Properties", value: "Bloom"},
    {label: "Tiger group", value: "Tiger group"},
    // {label: "Danube Properties", value: "Danube"},
    // {label: "Falcon City of Wonders LLC", value: "Falcon City of Wonders LLC"},
    // {label: "Five Holdings", value: "Five Holdings"},
    // {label: "Green Group", value: "Green Group"},
    // {label: "ICD Brookfield", value: "ICD Brookfield"},
    // {label: "Iman Developers", value: "Iman Developers"},
    // {label: "Indigo Properties", value: "Indigo"},
    // {label: "Ishraqah", value: "Ishraqah"},
    // {label: "Jumeirah Golf Estates", value: "Jumeirah Golf Estates"},
    // {label: "Jumeirah Group", value: "Jumeirah Group"},
    // {label: "Liv Developers", value: "Liv Developers"},
    // {label: "Lootah Real Estate Development", value: "Lootah Real Estate Development"},
    // {label: "Meraki Developers", value: "Meraki Developers"},
    // {label: "Meydan Sobha", value: "Meydan Sobha"},
    // {label: "Nshama", value: "Nshama"},
    // {label: "Palma Development", value: "Palma Development"},
    // {label: "Pantheon Development", value: "Pantheon Development"},
    {label: "Select Group", value: "Select Group"},
    {label: "Azizi", value: "Azizi"},
    {label: "OCTA Properties", value: "OCTA Properties"},
    // {label: "Sobha Developers", value: "Sobha Developers"},
    // {label: "Sweid & Sweid", value: "Sweid & Sweid"},
    // {label: "Tebyan", value: "Tebyan"},
    // {label: "The First Group", value: "The First Group"},
    // {label: "Union Properties", value: "Union"},
    // {label: "Victory Heights", value: "Victory Heights"},
    // {label: "Wasl Properties", value: "Wasl Properties"},
    // {label: "Westar Properties", value: "Westar"},
]

export const completionRange = [
    {label: "Completion", value: ""},
    {label: "2017", value: "2017"},
    {label: "2018", value: "2018"},
    {label: "2019", value: "2019"},
    {label: "2020", value: "2020"},
    {label: "2021", value: "2021"},
    {label: "2022", value: "2022"},
    // {label: "2023", value: "2023"},
    // {label: "2024", value: "2024"},
    // {label: "2025", value: "2025"},
    // {label: "2026", value: "2026"},
    // {label: "2027", value: "2027"}
]
export const RentalPeriod = [
    {label: "Rental Period", value: ""},
    {label: "Yearly", value: "yearly"},
    {label: "Monthly", value: "monthly"},
    {label: "Weekly", value: "weekly"},
    {label: "Daily", value: "daily"},  
]
